/**
 *  Писать пора описание.
 *
 *   data-parallax data-speed="180"
 *
 *   data-speed -- в процентах. логично от 1 до 99. Нормально от 10 до 15
 *
 * */

import {onDomReady} from "../../components/dynamic/observer";

class ImageObject {
    constructor(container) {
        this.container = container;

        this.update();
    }

    update() {
        let posElemScreenTop = this.container.getBoundingClientRect().top;
        let posElemScreenBottom = this.container.getBoundingClientRect().bottom;

        let clientHeight = this.container.clientHeight;
        let fullHeight = window.innerHeight + clientHeight;

        let strafe = clientHeight * this.container.dataset.speed / 100;

        let delta = 0;
        if (this.container.dataset.delta) {
            delta = clientHeight * this.container.dataset.delta / 100;
        }

        if (strafe > 0) {
            if (posElemScreenBottom > 0 && (posElemScreenTop > (0 - clientHeight))) {
                let offset = strafe * (1 - (Math.pow(posElemScreenBottom, 1.05) + clientHeight) / fullHeight) - delta;
                this.container.style.transform = `translate3d(0, ${offset}px, 0)`;
            }
        } else {
            if (posElemScreenBottom > 0 && (posElemScreenTop > (0 - clientHeight))) {
                let offset = strafe * (1 - (posElemScreenTop + clientHeight) / fullHeight) + delta;
                this.container.style.transform = `translate3d(0, ${offset}px, 0)`;
            }
        }

    }
}

class Parallax {
    objects = [];
    offsetY = 0;
    lastOffsetY = 0;

    constructor() {
        this.collect();
        this.eventListeners();
        this.update();
    }

    collect() {
        this.objects = [];
        Array.from(document.querySelectorAll('[data-parallax]')).forEach((container) => {
            this.objects.push(new ImageObject(container));
        });
    }

    eventListeners() {
        const instance = this;

        document.addEventListener('scroll', (event) => {
            instance.offsetY = window.pageYOffset;
        });

    }

    update() {
        if (this.lastOffsetY !== this.offsetY) {
            this.objects.forEach((object) => {
                object.update();
            });

            this.lastOffsetY = this.offsetY
        }

        window.requestAnimationFrame(this.update.bind(this));
    }
}

function init() {
    const parallax = new Parallax();
}

onDomReady(() => {
    init();
});