require('../scss/app.scss');
require('./svg/svg.js');

require('../fonts/Roboto_Flex/stylesheet.css');

window.noZensmooth = true;

require('../vue/app');
require('./filters/project-filters');
require('@vueform/slider/themes/default.css')
require('fslightbox');

require('./sliders/sliders.js');

require('./popup/popup.js');
require('./timer/timer.js');

require('./common/true-vh');
require('./common/goals2');
require('./common/js-validation');
require('./common/links');
require('./common/parallax');
require('./common/container-height');
require('./common/switcher');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/map');
require('./common-map/map');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/text-wrap-creator');
require('./common/hamburger');
require('./common/hoverListener');
require('./common/follower');
require('./common/underline-text');
require('./common/print');
require('./common/calltouch');
require('./common/fav-success');
require('./common/disable-submit-buttons');

require('./project-map/index')

require('./lazy/lazy');

require('./scroll/smooth-scroll');

require('./accordion/initializator');
require('./accordion/switchable-accordion');

require('./field/phone.js');
require('./field/floating.js');

require('./genplan/index');
require('./header/index');
require('./genplan/tabClick');

// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');

require('../../src/GlobalFav/Static/global-fav');
