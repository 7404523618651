import {onDomChanges, onDomReady} from "../../components/dynamic/observer";
// import {timeoutDelay} from "../../components/dynamic/timeout-delay";

class Follower {
    limits = undefined;
    offsetY = undefined;
    ease = undefined;
    observeAreas = [];
    cursor = undefined;
    lastActiveImage = undefined;
    largeMinWidth = undefined;
    item = undefined;

    constructor(selector) {
        selector.dataset.initialize = "true";
        this.container = selector;
        if (selector.dataset.followerContainer) {
            this.limits = JSON.parse(selector.dataset.followerContainer);
        }

        const rootStyles = getComputedStyle(document.documentElement);
        this.largeMinWidth = rootStyles.getPropertyValue('--large-min-width') || 1280;

        this.offsetY = 0;
        this.ease = 0.05;
        this.cursor = {
            x: 0,
            y: 0,
        };
        this.currentImage = {
            x: 0,
            y: 0,
        };
        this.lastActiveImage = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        };


        if (document.body.getBoundingClientRect().width >= this.largeMinWidth) {
            this.bindObserveAreas();
            this.eventListeners();
            this.update();
        }
    }

    bindObserveAreas() {
        this.observeAreas = Array.from(this.container.querySelectorAll('[data-follow-item]'));
    }

    update() {
        if (this.item) {
            this.lerp();
            this.setImageY();
            this.setImageX();
        }
        const instance = this;
        window.requestAnimationFrame(() => {
            instance.update();
        });
    }

    setImageX() {
        const itemImage = this.item.querySelector('[data-follow-picture]');
        itemImage.style.left = `${this.currentImage.x}px`;
    }

    setImageY() {

        const itemImage = this.item.querySelector('[data-follow-picture]');
        itemImage.style.top = `${this.currentImage.y}px`;
    }

    computedY() {
        this.lastActiveImage.y = this.cursor.y - this.lastActiveImage.height / 2 + this.offsetY;
    }

    computedX() {
        const boundingItem = this.item.getBoundingClientRect();

        if (this.limits) {
            if (this.cursor.x < (boundingItem.left + (boundingItem.width * this.limits.left))) {
                this.lastActiveImage.x = boundingItem.left + (boundingItem.width * this.limits.left) - this.lastActiveImage.width / 2;
            } else if (this.cursor.x > (boundingItem.left + (boundingItem.width * this.limits.right))) {
                this.lastActiveImage.x = boundingItem.left + (boundingItem.width * this.limits.right) - this.lastActiveImage.width / 2;
            } else {
                this.lastActiveImage.x = this.cursor.x - this.lastActiveImage.width / 2;
            }
        } else {
            this.lastActiveImage.x = this.cursor.x - this.lastActiveImage.width / 2;
        }
    }

    setImageVisible(item) {
        const itemImage = item.querySelector('[data-follow-picture]');
        itemImage.style.opacity = '1';
    }

    removeVisibleFromImage(item) {
        const itemImage = item.querySelector('[data-follow-picture]');
        if (!item.dataset.removeBlock) {
            itemImage.style.transitionDuration = '.15s';
        }
        itemImage.style.opacity = '0';
        itemImage.style.left = '';
        itemImage.style.top = '';
        itemImage.dataset.removeBlock = '';
    }

    lerp() {
        this.currentImage.x = (1 - this.ease) * this.currentImage.x + this.ease * this.lastActiveImage.x;
        this.currentImage.y = (1 - this.ease) * this.currentImage.y + this.ease * this.lastActiveImage.y;
    }

    eventListeners() {
        const instance = this;

        document.addEventListener('ArticlesContentReplaced', () => {
            this.bindObserveAreas();
            this.eventListeners();
        })

        document.addEventListener('scrolling', (e) => {
            this.offsetY = e.detail.offsetY;
        });

        document.addEventListener('headerOpened', () => {this.offsetY = 0});

        window.addEventListener('mousemove', (e) => {
            instance.cursor.x = e.clientX;
            instance.cursor.y = e.clientY;

            if (this.item) {
                this.computedY();
                this.computedX();
            } else {
                this.currentImage.x = instance.cursor.x - instance.lastActiveImage.width / 2;
                this.currentImage.y = instance.cursor.y - instance.lastActiveImage.height / 2;
            }

        });

        instance.observeAreas.forEach((item) => {
            const itemImage = item.querySelector('[data-follow-picture]');
            if (itemImage) {
                const bounding = itemImage.getBoundingClientRect();
                this.lastActiveImage.width = bounding.width;
                this.lastActiveImage.height = bounding.height;

                item.addEventListener('mouseenter', () => {
                    itemImage.querySelector('[data-follow-image]').style.transform = 'scale(1)';
                    if (this.item !== item) {
                        if (this.item) {
                            this.item.dataset.removeBlock = "true";
                            const currentImage = this.item.querySelector('[data-follow-picture]');
                            currentImage.style.transitionDuration = '0s';
                            this.item.dataset.removeBlock = "";
                        } else {
                            itemImage.style.transitionDuration = '.15s';
                        }

                        this.item = item;
                    }

                    this.setImageVisible(item);
                }, false);

                item.addEventListener('mouseleave', () => {
                    setTimeout(() => {
                        itemImage.querySelector('[data-follow-image]').style.transform = 'scale(1.4)';
                    }, 150);
                    instance.removeVisibleFromImage(item);
                }, false);

                itemImage.addEventListener('resize', (e) => {
                    this.lastActiveImage.width = bounding.width;
                    this.lastActiveImage.height = bounding.height;
                });
            }
        });
    }
}

function init() {
    let containers = document.querySelectorAll('[data-follower-container]:not([data-initialize="true"])');
    containers.forEach((item) => {
        const follower = new Follower(item);
    });
}

onDomReady(() => {init()});
onDomChanges(() => {init()});