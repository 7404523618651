import { useFilterStore } from "../stores/filter";
import { watch } from "vue";
import { storeToRefs } from "pinia";

class FilterManager {
    constructor(filterKey, options)
    {
        this.filterKey = filterKey;
        this.filterStore = useFilterStore();
      // State of old values
        this.oldValues = {};
        this.options = {...{
          // Получать новые значения с бэка вместе с информацией о полях
            updateValues: false
            }, ...options}
      // event -> array of callbacks
        this.subscribers = {};
        this.bindEvents();
    }

    on(event, callback)
    {
        const eventCallbacks = this.subscribers[event] ? this.subscribers[event] : [];
        eventCallbacks.push(callback);
    }

    trigger(event, args)
    {
        const eventCallbacks = this.subscribers[event] ? this.subscribers[event] : [];
        eventCallbacks.forEach((callback) => {
            callback(...args);
        });
    }

    bindEvents()
    {
        document.addEventListener('filter:submit', (e) => {
            if (e.detail.filterKey !== this.filterKey) {
                return;
            }
            this.submit();
        });

        document.addEventListener('filter:update', (e) => {
            if (e.detail.filterKey !== this.filterKey) {
                return;
            }
            this.update();
        });

        this.oldValues = JSON.parse(JSON.stringify(this.filterStore.filtersValues))
        const { filtersValues } = storeToRefs(this.filterStore);
        watch(filtersValues, (newValues) => {
            const newValuesDetached = JSON.parse(JSON.stringify(newValues));

            this.trigger('filter:values-changed', [
            this,
            this.oldValues,
            newValuesDetached
            ]);

          document.dispatchEvent(new CustomEvent('filter:values-changed', {
                detail: {
                    filterManager: this,
                    oldValues: this.oldValues,
                    newValues: newValuesDetached
                }
            }));

          this.oldValues = newValuesDetached
        }, { deep: true });
    }

    async submit()
    {
        document.dispatchEvent(new CustomEvent('filter:before-submit', {
            detail: {
                filterKey: this.filterKey,
                filterManager: this
            }
        }));

        this.trigger('filter:before-submit', [
        this
        ]);

        const content = await this.getContent();
        const page = this.htmlToElement(content);
      // Найти и установить данные
        this.readDataFromDocument(page);
      // Найти и обновить блоки
        this.updateBlocks(page);

        document.dispatchEvent(new CustomEvent('filter:after-submit', {
            detail: {
                filterKey: this.filterKey,
                filterManager: this
            }
        }));

        this.trigger('filter:after-submit', [
        this
        ]);
    }

    async update()
    {
        document.dispatchEvent(new CustomEvent('filter:before-update', {
            detail: {
                filterKey: this.filterKey,
                filterManager: this
            }
        }));

        this.trigger('filter:before-update', [
        this
        ]);

        const content = await this.getContent();
        const page = this.htmlToElement(content);

      // Найти и установить данные
        this.readDataFromDocument(page);

        document.dispatchEvent(new CustomEvent('filter:after-update', {
            detail: {
                filterKey: this.filterKey,
                filterManager: this
            }
        }));

        this.trigger('filter:after-update', [
        this
        ]);
    }

    readDataFromDocument(document, setUpValues)
    {
        const script = document.querySelector(`[data-filter-metadata][data-filter-key="${this.filterKey}"]`);
        if (!script) {
            console.error('Could not parse data block from html response');
            return;
        }

        let data = null;
        try {
            data = JSON.parse(script.innerText)
        } catch (e) {
            console.error('Script`s content are not json', script);
            return;
        }

        setUpValues = setUpValues | this.options.updateValues;

        this.setFilterData(data, setUpValues);
    }

    htmlToElement(html)
    {
        const template = document.createElement('template');
        html = html.trim();
        template.innerHTML = html;
        return template.content;
    }

    buildObjectUrlParams(item, params, name)
    {
        if (typeof item === 'object' && item !== null) {
            for (const key in item) {
                const subItem = item[key];
                const subName = name ? `${name}[${key}]` : key;
                this.deleteFromParams(subName, params);
                this.buildObjectUrlParams(subItem, params, subName);
            }
            return;
        }

        if (Array.isArray(item)) {
            this.deleteFromParams(name, params);
            for (const key in item) {
                const subItem = item[key];
                const subName = name ? `${name}[]` : key;
                this.buildObjectUrlParams(subItem, params, subName);
            }
            return;
        }

        if (item === null) {
            this.deleteFromParams(name, params);
            params.append(name, '');
            return;
        }

        this.deleteFromParams(name, params);
        params.append(name, item);
    }

    deleteFromParams(key, params)
    {
        params.forEach((paramValue, paramKey) => {
            if (
            paramKey.startsWith(key + '[') ||
            paramKey === key
        ) {
            params.delete(paramKey);
            }
        });
    }

    async getContent()
    {
        let action = this.filterStore.getAction(this.filterKey);
        if (!action) {
            action = window.location.href;
        }

      // Получаем и сериализуем данные
        const values = this.filterStore.getValues(this.filterKey);

        const url = new URL(action);
        const path = url.origin + url.pathname;

      // Строим параметры запроса
        const params = new URLSearchParams();
      // Добавляем в параметры текущий запрос
        url.searchParams.forEach((paramValue, paramName) => {
            params.append(paramName, paramValue)
        });
      // Дополняем его данными из фильтра
        this.buildObjectUrlParams(values, params);

      // Строим url
        const actionUrl = `${path}?${params.toString()}`;

        const response = await fetch(actionUrl);
        if (!response.ok) {
            throw response.error();
        }

        return await response.text();
    }

    setFilterData(data, setUpValues)
    {
        document.dispatchEvent(new CustomEvent('filter:before-set-filter-data', {
            detail: {
                filterKey: this.filterKey,
                filterManager: this
            }
        }));

        this.trigger('filter:before-set-filter-data', [this]);

        this.filterStore.setFilterData(this.filterKey, data, setUpValues);

        document.dispatchEvent(new CustomEvent('filter:after-set-filter-data', {
            detail: {
                filterKey: this.filterKey,
                filterManager: this
            }
        }));

        this.trigger('filter:after-set-filter-data', [this]);
    }

    updateBlocks(pageContent)
    {
        document.dispatchEvent(new CustomEvent('filter:before-update-blocks', {
            detail: {
                filterKey: this.filterKey,
                filterManager: this
            }
        }));

        this.trigger('filter:before-update-blocks', [this]);

        document.querySelectorAll(`[data-filter-result][data-filter-key="${this.filterKey}"]`).forEach((block) => {
            const newBlock = pageContent.querySelector(
                `[data-filter-result="${block.dataset.filterResult}"][data-filter-key="${this.filterKey}"]`
            );
        if (!newBlock) {
            console.info('Could not find new block element on page, skip...', block);
            return;
        }

        block.replaceWith(newBlock);
        });

        document.dispatchEvent(new CustomEvent('filter:after-update-blocks', {
            detail: {
                filterKey: this.filterKey,
                filterManager: this
            }
        }));

        this.trigger('filter:after-update-blocks', [this]);

        document.dispatchEvent(new Event('DOMContentMutated'));
    }
}

export class FilterManagerPool {
    static pool = {};

  /**
   * @param filterKey string
   * @param options object
   * @return FilterManager
   */
    static getManager(filterKey, options)
    {
        if (!FilterManagerPool.pool[filterKey]) {
            FilterManagerPool.pool[filterKey] = new FilterManager(filterKey, options);
        }
        return FilterManagerPool.pool[filterKey];
    }

    static getCurrentManager()
    {
        return Object.values(FilterManagerPool.pool)[0]
    }
}