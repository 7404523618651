import {Controller, EffectCreative, EffectFade, Mousewheel, Navigation, Pagination, Swiper} from 'swiper';
import {onDomReady} from '../../components/dynamic/observer';

function init() {
    const container = document.querySelector('[data-interiors-slider-container]');

    if (container) {
        const imageSliderEl = container.querySelector('[data-interiors-image-slider]');
        const textSliderEl = container.querySelector('[data-interiors-text-slider]');

        let imageSlider;
        let textSlider;
        let sliderSpeed = 1500;

        const leftArrow = container.querySelector('[data-interiors-slider-left]');
        const rightArrow = container.querySelector('[data-interiors-slider-right]');

        if (imageSliderEl) {
            imageSlider = new Swiper(imageSliderEl, {
                modules: [Navigation, Pagination, EffectCreative, Controller],
                speed: sliderSpeed,
                preventInteractionOnTransition: true,
                loop: true,
                freeMode: false,
                navigation: {
                    prevEl: leftArrow,
                    nextEl: rightArrow,
                },
            });
        }

        if (textSliderEl) {
            textSlider = new Swiper(textSliderEl, {
                modules: [EffectFade, Controller, EffectCreative],
                allowTouchMove: false,
                preventInteractionOnTransition: true,
                loop: true,
            });
        }

        imageSlider.on('slideChange', () => {
            setTimeout(() => {
                let index = imageSlider.realIndex + 1;
                textSlider.slideTo(index, 0);
            }, 100);
        });
    }
}

onDomReady(() => setTimeout(() => init(), 100));