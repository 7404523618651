import { onDomReady } from '../../components/dynamic/observer';
class Header {
  constructor(selector) {
    this.header = selector;
    this.isOpened = false;

    this.eventListeners();
  }

  eventListeners() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        this.header.classList.add('_sticky');
      } else {
        this.header.classList.remove('_sticky');
      }
    });
  }
}

onDomReady(() => {
  const headerSelector = document.querySelector('[data-header]');
  if (headerSelector) {
    new Header(headerSelector);
  }
});