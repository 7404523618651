import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

new LiveEvent('click', '[data-modal]', function openModal(e) {
    e.preventDefault();
    let link = this;
    let modal = new Modal(this, {
        animation: {
            classIn: '_open',
            classOut: '_close',
            timeoutIn: 16,
            timeoutOutClass: 16,
            timeoutOut: 816,
        },
        theme: link.dataset.theme ? link.dataset.theme : 'default',
        closerText: '',
        onAfterOpen: function () {
            setTimeout(() => {
                if (this.content.querySelector('[data-modal-form]')) {
                    this.content.querySelector('[data-modal-form]').classList.remove('_awaiting-animation');
                }
            }, 16);
        },
        onBeforeClose: function () {
        },
        onFormSuccess: function () {
            const submitEvent = new Event('modal-form:success');
            submitEvent.element = link;
            document.dispatchEvent(submitEvent);
            this.content.querySelector('[data-modal-success]').classList.remove('_awaiting-animation');
        },
    });
    return false;
});

new LiveEvent('click', '[data-popup]', function openModal(e) {
    e.preventDefault();
    let link = this;
    let modal = new Modal(this, {
        animation: {
            classIn: '_open',
            classOut: '_close',
            timeoutIn: 16,
            timeoutOutClass: 16,
            timeoutOut: 816,
        },
        theme: link.dataset.theme ? link.dataset.theme : 'default',
        closerText: '',
        onAfterOpen: function () {
            setTimeout(() => {
                if (this.content.querySelector('[data-modal-form]')) {
                    this.content.querySelector('[data-modal-form]').classList.remove('_awaiting-animation');
                }
                document.body.style.overflow = 'scroll';
            }, 16);
        },
        onBeforeClose: function () {
        },
        onFormSuccess: function () {
            const submitEvent = new Event('modal-form:success');
            submitEvent.element = link;
            document.dispatchEvent(submitEvent);
            this.content.querySelector('[data-modal-success]').classList.remove('_awaiting-animation');
        },
    });
    return false;
});

new LiveEvent('click', '[data-genplan-modal]', function openModal(e) {
    e.preventDefault();
    let link = document.createElement('a');
    link.setAttribute('href', this.dataset.genplanModal);
    let modal = new Modal(link, {
        animation: {
            classIn: '_opened',
            classOut: '_closed',
            timeoutIn: 16,
            timeoutOut: 616,
        },
        closerText: '',
    });
    return false;
});
