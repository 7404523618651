import LiveEvent from "../../components/live/live";
import {onDomReady} from "../../components/dynamic/observer";

function addUnderlined() {
    let targets = document.querySelectorAll('[data-underline-text]');

    targets.forEach((item) => {
        item.addEventListener('mouseenter', () => {
            if (!item.classList.contains('underline')) {
                item.classList.add('underline');
                setTimeout(() => {
                    item.classList.remove('underline');
                }, 850);
            }
        });
    });
}

onDomReady(() => {
    addUnderlined()
});