import { onDomChanges } from '../../components/dynamic/observer';

function getTimeRemaining(endtime) {
  const t = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((t / 1000) % 60);
  return {
    total: t,
    seconds,
  };
}

function initializeClock(id, endtime) {
  const clocks = document.querySelectorAll('[data-timer]');
  let isResolve = false;
  document.addEventListener('resolve-button-click', (e) => {
    isResolve = true;
  });
  clocks.forEach((clock) => {
    const secondsSpan = clock.querySelectorAll('.seconds');
    function updateClock() {
      const t = getTimeRemaining(endtime);
      const timeinterval = setTimeout(updateClock, 1000);
      secondsSpan.forEach((second) => {
        second.innerHTML = (`0${t.seconds}`).slice(-2);
      });
      if (isResolve === true) {
        clearInterval(timeinterval);
      } else if (t.total <= 0) {
        clearInterval(timeinterval);
        const closer = document.querySelector('.modal__closer');
        closer.click();
      }
    }

    updateClock();
  });
}

function init() {
  onDomChanges(() => {
    const element = document.querySelector('[data-timeout]');
    if (element) {
      const data = element.dataset;
      const timeout = Number(data.timeout.toString());
      const dateNow = new Date();
      const dateEnd = dateNow.setSeconds(dateNow.getSeconds() + timeout);
      const deadline = new Date(dateEnd);
      initializeClock('clock', deadline);
    }
  });
}

document.addEventListener('DOMContentMutated', init);