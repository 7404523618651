import bigCirclePath from '!file-loader!../../images/svg/shadowed-big-circle.svg';
import smallCirclePath from '!file-loader!../../images/svg/shadowed-small-circle.svg';
import {MapObject} from './MapObject';

export default class ProjectMap {
    constructor(rootElement, settings) {
        this.settings = {
            lat: 0,
            lng: 0,
            zoom: 14,
            icon: null,
            color: null,
        };
        Object.assign(this.settings, rootElement.dataset, settings);
        this.objects = [];
        this.rootElement = rootElement;
        this.mapElement = rootElement.querySelector('[data-project-map-canvas]');
        this.map = null;
        this.selectedCategories = [];
        this.cluster = null;
        this.initMapPending();
    }

    getLat() {
        return parseFloat(this.settings.lat);
    }

    getLng() {
        return parseFloat(this.settings.lng);
    }

    getZoom() {
        return parseInt(this.settings.zoom, 10);
    }

    getIcon() {
        return this.settings.icon;
    }

    getColor() {
        return this.settings.color ? this.settings.color : '#000';
    }

    initMapPending() {
        if (window.ymaps) {
            this.initMap();
            return;
        }
        setTimeout(() => {
            this.initMapPending();
        }, 500);
    }

    initMap() {
        ymaps.ready(() => {
            const rect = this.mapElement.getBoundingClientRect();
            this.map = new ymaps.Map(this.mapElement, {
                center: [this.getLat(), this.getLng()],
                zoom: this.getZoom(),
                controls: ['zoomControl'],
            }, {
                zoomControlPosition: {right: 10, top: 'auto', left: 'auto', bottom: (rect.height / 2 - 30) + 'px'},
                zoomControlSize: 'small',
            });
            this.map.behaviors.disable('scrollZoom');
            if (window.innerWidth <= 1024) {
                this.map.behaviors.disable('drag');
            }
            this.initMainPlacemark();
            this.initObjects();
        });
    }

    initMainPlacemark() {
        const hintElement = this.rootElement.querySelector('[data-project-map-hint]');
        let hintLayout = null;
        if (hintElement) {
            hintLayout = ymaps.templateLayoutFactory.createClass(
                hintElement.innerHTML, {
                    /**
                     * Defining the getShape method,
                     * which will return the size of the hint layout.
                     * This is necessary in order for the hint to automatically
                     * move its position when going off the map.
                     */
                    getShape: function () {
                        const el = this.getElement();
                        let result = null;
                        if (el) {
                            const firstChild = el.firstChild;
                            result = new ymaps.shape.Rectangle(
                                new ymaps.geometry.pixel.Rectangle([
                                    [0, 0],
                                    [firstChild.offsetWidth, firstChild.offsetHeight],
                                ]),
                            );
                        }
                        return result;
                    },
                },
            );
        }

        const mainPlacemark = new ymaps.Placemark([this.getLat(), this.getLng()], {}, {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#imageWithContent',
            // Своё изображение иконки метки.
            iconImageHref: bigCirclePath,
            // Размеры метки.
            iconImageSize: [69, 69],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-34, -33],
            // Смещение слоя с содержимым относительно слоя с картинкой.
            iconContentOffset: [0, 0],
            // Макет содержимого.
            iconContentLayout: ymaps.templateLayoutFactory.createClass(
                `<div class="project-map__main-icon">
            <img class="project-map__main-icon-img" src="${this.getIcon()}"/>
         </div>`,
            ),
            hint: hintLayout ? '' : undefined,
            hintLayout: hintLayout,
        });
        this.map.geoObjects.add(mainPlacemark);
    }


    initObjects() {
        const elements = this.rootElement.querySelectorAll('[ data-project-map-object]');
        elements.forEach((element) => {
            const mapObject = new MapObject(element);
            this.objects.push(mapObject);
        });
        this.cluster = new ymaps.Clusterer({
            hasBalloon: false,
            clusterIcons: [
                {
                    href: '',
                    size: [40, 40],
                    offset: [0, 0],
                },
            ],
            clusterIconContentLayout: ymaps.templateLayoutFactory.createClass(
                `<div class="project-map__cluster-icon" style="background-color: ${this.getColor()}">
            {{ properties.geoObjects.length }}
         </div>`,
            ),
            groupByCoordinates: false,
        });
        const placemarks = [];
        this.objects.forEach((mapObject) => {
            const placemark = this.initPlacemarkForObject(mapObject);
            placemarks.push(placemark);
        });
        this.cluster.add(placemarks);
        this.map.geoObjects.add(this.cluster);
    }

    initPlacemarkForObject(mapObject) {
        let iconSize = 54;
        if (window.innerWidth < 768) {
            iconSize = 40;
        }

        const iconPlacemark = new ymaps.Placemark([mapObject.getLat(), mapObject.getLng()], {
            hintContent: mapObject.getName(),
        }, {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#imageWithContent',
            // Своё изображение иконки метки.
            iconImageHref: smallCirclePath,
            // Размеры метки.
            iconImageSize: [iconSize, iconSize],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-iconSize/2, -iconSize/2],
            // Смещение слоя с содержимым относительно слоя с картинкой.
            iconContentOffset: [0, 0],
            // Макет содержимого.
            iconContentLayout: ymaps.templateLayoutFactory.createClass(
                `<div class="project-map__object-icon">
            <img class="project-map__icon-img" src="${mapObject.getIcon()}"/>
         </div>`,
            ),
        });
        mapObject.setPlacemark(iconPlacemark);
        return iconPlacemark;
    }

}