import { Scene } from './scene';

export class Genplan {
  constructor(rootElement, settings) {
    this.settings = settings;
    if (this.settings.isTouch === undefined) {
      this.settings.isTouch = this.isTouchDevice();
    }
    this.rootElement = rootElement;
    this.loaderElement = rootElement.querySelector('[data-gen-loader]');
    this.modalsElement = document.createElement('div');
    this.scenes = [];
    this.currentScene = null;
    this.initModalsElement();
    this.initScenes();
    this.go();
    this.bindExternalEvents();
  }

  async go() {
    await this.startFirstScene(
      this.rootElement.dataset.firstScene,
    );
  }

  initModalsElement() {
    this.modalsElement.classList.add('genplan__modals');
    this.rootElement.appendChild(this.modalsElement);
  }

  isTouchDevice() {
    return (('ontouchstart' in window)
      || (navigator.maxTouchPoints > 0)
      || (navigator.msMaxTouchPoints > 0));
  }

  initScenes() {
    // console.log(this.rootElement.querySelectorAll('[data-gen-scene]'));
    // Собираем и создаем все сцены
    this.rootElement.querySelectorAll('[data-gen-scene]')
      .forEach((sceneElement) => {
        const sceneModalsElement = document.createElement('div');
        sceneModalsElement.classList.add('genplan__scene-modals');
        this.modalsElement.appendChild(sceneModalsElement);

        this.scenes.push(
          new Scene(
            sceneElement,
            sceneModalsElement,
            this.settings,
          ),
        );
      });
  }

  async startFirstScene(firstSceneName) {
    if (firstSceneName) {
      await this.openSceneByName(firstSceneName);
      return;
    }

    const firstScene = this.scenes[0];
    if (firstScene) {
      this.scenes.forEach((scene) => {
        if (firstScene !== scene) {
          scene.hide();
        }
      });
      await this.openScene(firstScene);
    } else {
      console.error('Could not determine first scene');
    }
  }

  bindExternalEvents() {
    // Слушаем ресайзы
    window.addEventListener('resize', () => {
      if (this.currentScene) {
        this.renderScene(this.currentScene);
      }
    });
    document.addEventListener('tabClick', () => {
      if (this.currentScene) {
        this.renderScene(this.currentScene);
      }
    })

    // Создаем следилку за data-gen-link-scene, которая по клику по этой ссылке будет открывать нужную сцену
    document.querySelectorAll('[data-gen-link-scene]').forEach((link) => {
      const sceneName = link.dataset.genLinkScene;
      link.addEventListener('click', () => {
        const scene = this.getSceneByName(sceneName);
        if (scene) {
          this.openScene(scene);
        }
      });
    });
    document.addEventListener('request-openen-genplan-scene', (e) => {
      const scene = this.getSceneByName(e.detail.sceneName);
      const genplanLink = document.querySelector("a[href='#menu-project-genplan']");
      if (scene && genplanLink) {
        genplanLink.click();
        this.openScene(scene);
      } else if (scene) {
        this.openScene(scene);
      }
    });
  }

  showPreloader() {
    if (this.loaderElement) {
      this.loaderElement.classList.add('_opened');
    }
  }

  hidePreloader() {
    if (this.loaderElement) {
      this.loaderElement.classList.remove('_opened');
    }
  }

  getMode() {
    if (this.settings.isTouch && window.innerWidth <= this.settings.scrollableToWidth) {
      return 'scrollable';
    }
    if (this.settings.size && this.settings.size === 'contain') {
      return 'contain';
    }
    return 'cover';
  }

  getSceneByName(sceneName) {
    return this.scenes.find(scene => scene.getName() === sceneName);
  }

  async openSceneByName(sceneName) {
    const foundScene = this.getSceneByName(sceneName);
    await this.openScene(foundScene);
  }

  async openScene(scene) {
    if (this.currentScene === scene) {
      return;
    }

    if (this.currentScene) {
      this.currentScene.hide();
    }
    this.renderScene(scene);
  }

  async renderScene(scene) {
    this.showPreloader();
    this.currentScene = scene;
    const rootBoundingClientRect = this.rootElement.getBoundingClientRect();
    await scene.render(
      rootBoundingClientRect.width,
      rootBoundingClientRect.height,
      this.getMode(),
    );
    scene.show();
    this.hidePreloader();
  }
}