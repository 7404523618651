import LiveEvent from "../../components/live/live";
import {scrollToTarget} from "../../components/scroll/scroll";
import {onDomReady} from "../../components/dynamic/observer";

new LiveEvent('click', '[data-scroll-link], .scroll-link', function scrollLink(e) {
    e.preventDefault();

    let target = null;
    if (this.dataset.selector) {
        target = document.querySelector(this.dataset.selector);
    } else {
        target = document.querySelector(this.getAttribute('href'));
    }
    let offset = parseInt(this.dataset.offset) || 0;
    if (target) {
        scrollToTarget(target, offset);
    }
});

new LiveEvent('click', '[data-toggle-link], .toggle-link', function toggleLink(e) {
    e.preventDefault();
    let target = document.querySelector(this.dataset.selector);
    target.classList.toggle(this.dataset.toggle);
});

// ya-share2

onDomReady(() => {
    let target = document.querySelector('.ya-share2__popup');
    if (target) {
        target.classList.add('ya-share2__popup_visible');
    }

    if (window.innerWidth > 768) {
        let iconContainers = document.querySelectorAll('.ya-share2__link');
        if (iconContainers) {
            iconContainers.forEach((iconContainer, i) => {
                if (i > 0) {
                    //копируем иконку и ее обертку
                    let iconWrapCopy = iconContainer.querySelector('.ya-share2__badge').cloneNode();
                    let iconCopy = iconContainer.querySelector('.ya-share2__icon').cloneNode();
                    // класс копии обертки
                    iconWrapCopy.classList.add('ya-share2__badge_copy');
                    //добавим обертку
                    iconContainer.appendChild(iconWrapCopy);
                    //добавим иконку
                    iconContainer.querySelector('.ya-share2__badge_copy').appendChild(iconCopy);
                }
            });
        }
    }
})

new LiveEvent('mouseenter', '.ya-share2__link_more', function toogleList(e) {
    e.preventDefault();
    let target = document.querySelector('.ya-share2__popup');
    if (target) {
        target.classList.toggle('ya-share2__popup_visible_local');
    }
});

new LiveEvent('mouseleave', '.ya-share2__link_more', function toogleList(e) {
    e.preventDefault();
    let target = document.querySelector('.ya-share2__popup');
    if (target) {
        target.classList.toggle('ya-share2__popup_visible_local');
    }
});

// //работает не чини

new LiveEvent('mouseenter', '.share', function toogleList(e) {
    e.preventDefault();
    let target = document.querySelector('.ya-share2__popup');
    if (target) {
        target.classList.toggle('ya-share2__popup_visible_local');
    }
});

new LiveEvent('mouseleave', '.share', function toogleList(e) {
    e.preventDefault();
    let target = document.querySelector('.ya-share2__popup');
    if (target) {
        target.classList.toggle('ya-share2__popup_visible_local');
    }
});

// /ya-share2