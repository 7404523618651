import DefaultFieldset from "./fieldset/DefaultFieldset.vue";
import DefaultField from "./field/DefaultField";
import RangeField from "./field/RangeField";
import DropdownField from "./field/DropdownField";
import CheckboxListField from "./field/CheckboxListField";

export const map = {
  // Fieldset
  DefaultFieldset,

  // Field
  DefaultField,
  RangeField,
  CheckboxListField,
  DropdownField
};