import { onDomReady } from '../../components/dynamic/observer';

class Popup {
    constructor() {
        this.target = document.querySelector('.popup-link');
        if (this.target) {
            this.timeoutSetting = this.target.dataset.popupTimeout * 1000;
            this.isPopupSee = sessionStorage.getItem('isPopupSee');
            if (this.target) {
                this.bind();
                this.bindMutated();
            }
        }
    }

    bind() {
        if (this.timeoutSetting !== 0) {
            this.initByTimeout();
        }
    }

    bindMutated() {
        document.addEventListener('DOMContentMutated', this.initResolve);
    }

    initByTimeout() {
        setTimeout(() => {
            this.openPopup();
        }, this.timeoutSetting);
    }

    openPopup() {
        if (this.target && !document.querySelector('body').classList.contains('modal-opened') && !this.isPopupSee) {
            this.target.click();
            sessionStorage.setItem('isPopupSee', 'isPopupSee');
            this.isPopupSee = true;
        }
    }

    initResolve() {
        const resolveButton = document.querySelector('[data-resolve-button]');
        const resolveScreen = document.querySelector('.popup-modal__resolve-screen');
        const popupScreen = document.querySelector('.popup-modal__form');
        resolveButton.addEventListener('click', (e) => {
            resolveScreen.classList.add('_hide');
            popupScreen.classList.add('_show');
            document.dispatchEvent(new CustomEvent('resolve-button-click'));
        });
    }
}

function initPopup() {
    onDomReady(() => {
        const popup = new Popup();
    });
}

document.addEventListener('DOMContentLoaded', initPopup);