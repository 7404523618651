import {ScrollSlider} from './scrollSlider/ScrollSlider';

window.addEventListener('load', () => {
    initSlider();
})

function initSlider() {
    if (window.innerWidth > 1199) {
        let slider = document.querySelector('[data-about-slider]');

        if (slider) {
            slider.classList.add('_scroller');
            new ScrollSlider(slider);
        }
    }
}
/**
 * Кастомный вертикальный слайдер с Мели. Надженый как швейцарские часы.
 */