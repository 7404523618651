import {onDomReady} from "../../components/dynamic/observer";

class ContainerObject {
    constructor(container)
    {
        this.container = container;

        this.update();
    }

    update()
    {
        let parallax = this.container.dataset.speed;
        let targetHeight = this.container.offsetHeight + parseInt(parallax);
        let target = this.container.querySelector('.data_target_height');

        target.style.height = `${targetHeight}px`;
    }
}

class HeightObject {
    objects = [];
    offsetY = 0;
    lastOffsetY = 0;

    constructor()
    {
        this.collect();
        this.eventListeners();
        this.update();
    }

    collect()
    {
        this.objects = [];
        Array.from(document.querySelectorAll('[data-container-height]')).forEach((container) => {
            this.objects.push(new ContainerObject(container));
        });
    }

    eventListeners()
    {
        window.addEventListener('resize', () => {
            this.update();
        });
    }

    update()
    {
        this.objects.forEach((object) => {
            object.update();
        });
    }
}

function init()
{
    const heightObject = new HeightObject();
}

onDomReady(() => {
    setTimeout(
        () => {
            init();
        },
        100
    );
});