const header = document.querySelector('[data-header]');
const hamburger = document.querySelector('[data-header-hamburger]');
const menu = document.querySelector('[data-header-menu]');
let links = document.querySelectorAll('[data-drop-link]');

// const dropMenuCatalogOpen = document.querySelector('[data-header-drop-menu-catalog-open]');
// const dropMenuCatalogClose = document.querySelector('[data-header-drop-menu-catalog-close]');
// const dropMenuCatalog = document.querySelector('[data-header-drop-menu-catalog]');
// const dropMenuCatalogCategoriesButton = document.querySelectorAll('[data-header-drop-menu-catalog-categories-button]');

if (header && hamburger && menu) {
    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle('_open');
        header.classList.toggle('_black');
        menu.classList.toggle('_open');

        if (menu.classList.contains('_open')) {
            menu.classList.add('_delay');

            let links = menu.querySelectorAll('.drop-menu__link');

            let time = links.length * 100;

            setTimeout(() => {
                menu.classList.remove('_delay');
            }, time);
        }
    });

    links.forEach((link) => {
        link.addEventListener('click', () => {
            hamburger.classList.remove('_open');
            header.classList.remove('_black');
            menu.classList.remove('_open');
        })
    });
}
