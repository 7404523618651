import { onDomReady } from '../../components/dynamic/observer';
class TabClick {
    constructor(selector) {
        this.tab = selector
        this.eventListeners();
    }

    eventListeners() {
        this.tab.addEventListener('click', () => {
            document.dispatchEvent(new CustomEvent('tabClick'));
        });
    }
}

onDomReady(() => {
    const headerSelector = document.querySelector('[data-gen-link]');
    if (headerSelector) {
        new TabClick(headerSelector);
    }
});