import {onDomReady} from "../../components/dynamic/observer";

function init() {
    let container = document.querySelector('[data-switcher-container]');

    if(container){
        let links = container.querySelectorAll('[data-switcher-link]');
        let targets = container.querySelectorAll('[data-switcher-target]');

        if (links && targets){
            links.forEach((link) => {
                link.addEventListener('click', (e) => {
                    e.preventDefault();
                    targets.forEach((target) => {
                        target.classList.remove('_visible');
                        if (link.dataset.switcherLink === target.dataset.switcherTarget) {
                            target.classList.add('_visible');
                        }
                    })
                });
            });
        }
    }
}

onDomReady(init);

/**
 * Структура
 *
 * <div data-switcher-container>
 *     <ul>
 *         <li class="_visible" data-switcher-target="{{ loop.index }}>
 *         </li>
 *     </ul>
 *     <ul>
 *         <li>
 *             <a data-switcher-link="{{ loop.index }}></a>
 *         </li>
 *     </ul>
 *
 * </div>
 *
*/
