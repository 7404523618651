import {timeoutDelay} from "../../components/dynamic/timeout-delay";
import {onDomReady} from "../../components/dynamic/observer";

/**
 * Что тут происходит?
 * 1. Ожидается событие DOMContentLoaded
 * 2. Создается экземпляр класса LazyLoader
 * 3. При инициализации собираются все элементы [data-lazy-load] без [data-lazy-initialized="true"]
 * 4. Из собранных элементов выбираются элементы с [data-important="true"]
 *  а) Есть один или более элемент с [data-important="true"]
 *    - Сохраняется количество картинок с [data-important="true"] в this.importantCount
 *    - Для этих элементов подставляется настоящая картинка
 *    - Указывается [data-lazy-initialized="true"]
 *    - При завершении отправляется событие ImageLoaded на document
 *    - При завершении загрузки картинки this.importantCount уменьшается на 1
 *    - Если это была последняя картинка, то
 *      - если уже сработало событие load у window, то вызывается this.bindOther() - биндинг остальных картинок
 *      - если событие load у window еще не сработало, то оно ожидается, а когда оно сработает вызовет this.bindOther()
 *   б) Если нет элементов с [data-important="true"]
 *    - если уже сработало событие load у window, то вызывается this.bindOther() - биндинг остальных картинок
 *    - если событие load у window еще не сработало, то оно ожидается, а когда оно сработает вызовет this.bindOther()
 * 5. this.bindOther()
 *  - Подставляются настоящие картинки
 *  - Указывается [data-lazy-initialized="true"]
 *  - При завершении отправляется событие ImageLoaded на document
 *
 * Что еще?
 * 1. Класс ожидает событие DOMContentMutated
 * 2. Выполняется повторная переинициализация
 *  - Поскольку старые элементы с [data-important="true"] биндятся сразу, на них уже висит [data-lazy-nitialized="true"]
 *  - TODO DOMContentMutated может сработать до загрузки всех старых картинок с [data-important="true"], что тогда?
 *  - Старые элементы без [data-important="true"] пойдут в очередь за новыми элементами с [data-important="true"]
 *
 *  Зачем [data-important="true"]?
 *  Скрипты, ожидающие загрузку изображений первого экрана должны отработать раньше,
 *  а не ждать пока загрузятся все картинки на странице. Картинки большие и их может быть много
 *
 *  TODO [data-important="true"] можно засунуть в DOMContentLoaded
 */
class LazyLoader {
  constructor() {
    this.importantCount = 0;
    this.windowLoaded = document.readyState === 'complete';
    this.start = new Date();
    this.init();
    this.eventListeners();
  }

  init() {
    this.collect();
    this.bind();
  }

  collect() {
    this.elements = Array.from(document.querySelectorAll('[data-lazy-load]:not([data-lazy-initialized="true"])'));
  }

  bind() {
    const importantList = this.elements.filter(element => element.dataset.important === 'true');
    this.importantCount = importantList.length;
    if (this.importantCount) {
      importantList.forEach(element => this.bindElement(element));
    } else if (this.windowLoaded) {
      this.bindOther();
    }
  }

  bindOther() {
    this.elements.filter(element => element.dataset.important !== 'true').forEach(element => {
      this.bindElement(element);
    });
  }

  bindElement(element) {
    if (element.dataset.lazyInitialized !== 'true') {
      this.setImg(element);
      this.setSources(element);
    }
  }

  setSources(element) {
    element.querySelectorAll('source').forEach((source) => {
      if (source.dataset.srcset) {
        source.srcset = source.dataset.srcset;
      }
    });
  }

  setImg(element) {
    const img = element.querySelector('img');
    if (img.dataset.src) {
      img.src = img.dataset.src;
    }

    if (img.complete) {
      this.onloadEvent(element);
    } else {
      img.onload = () => this.onloadEvent(element);
      img.onerror = () => this.onloadEvent(element);
    }
    element.dataset.lazyInitialized = 'true'
    img.dataset.lazyInitialized = 'true'
  }

  onloadEvent(element) {
    const img = element.querySelector('img');
    img.dataset.lazyLoaded = 'loaded';
    document.dispatchEvent(new CustomEvent('ImageLoaded', {
      detail: { pictureElement: element }
    }));

    if (element.dataset.important === 'true') {
      this.importantCount--;
      if (!this.importantCount && this.windowLoaded) {
        this.bindOther();
      }
    }
  }

  windowOnLoad() {
    this.windowLoaded = true;
    if (!this.importantCount) {
      this.bindOther();
    }
  }

  eventListeners() {
    const instance = this;
    document.addEventListener('DOMContentMutated', () => {
      instance.init();
    })

    window.addEventListener('load', () => instance.windowOnLoad());
  }
}
onDomReady(() => new LazyLoader());
